<template>
  <div>
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="d-flex">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{ $t("message.tracking") }} </div>
              <el-input :placeholder="$t('message.search')" v-model="filterForm.search" size="large"></el-input> 
            </div>
				  </el-col>
		    </el-row>
      </div>
      <hr>
      <div v-loading="loadingData" class="mm_min_width mb-5">
        <div>
          <h1 v-if="searchedDeals && searchedDeals.length == 0" class="text-center">
            {{ $t('message.no_data_yet') }}
          </h1>
        </div>
        <el-collapse v-model="activeNames" @change="handleChange" class="mm_background">
          <el-collapse-item v-for="(deal, deal_index) in searchedDeals" :name="deal.id">
            <!-- DEAL TITLE LINE -->
            <template slot="title">
              <div class="mm_font_16 w-100 mm_space_between mm_blue p-2">
                <div>
                  <span class="font-bold"> {{ $t('message.deal') + ' № ' + deal.id }} </span>
                  <span class="ml-5"> {{ $t('message.manager') + ': ' + (deal.user ? deal.user.name : '') }} </span>
                  <span class="ml-5"> {{ $t('message.created_at') + ': ' + (deal.created_at) }} </span>
                </div>
                <div>
                  <span class="font-bold">{{ $t('message.paid') }}:  {{ deal.paid_money | formatMoney }}</span>
                  <span class="font-bold ml-5"> {{ $t('message.total') }}  {{ (deal.product_total + deal.total_services_price) | formatMoney }}</span>
                </div>
              </div>
            </template>
            <!-- DEAL INFO -->
            <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4 mm_font_14">
                <div class="mm_space_between mb-2">
                  <span class="font-bold">ID: {{ deal.id }}</span>
                  <el-button @click="editDeal(deal)" icon="el-icon-view" type="primary">{{ $t('message.show') }}</el-button>
                </div>
                <el-row :gutter="20" class="mm_font_14">
                  <!-- Clients  -->
                  <el-col v-if="deal.client" :span="8">
                    <span class="font-bold"> {{ $t('message.sender') }}: </span> 
                    <span> {{ deal.client.custom_id }}, {{ deal.client.name }} </span>
                  </el-col>
                  <el-col v-if="deal.reciever" :span="8">
                    <span class="font-bold"> {{ $t('message.reciever') }}: </span> 
                    <span> {{ deal.reciever.custom_id }}, {{ deal.reciever.name }} </span>
                  </el-col>
                  <el-col v-if="deal.partner" :span="8">
                    <span class="font-bold"> {{ $t('message.partner') }}: </span> 
                    <span> {{ deal.partner.custom_id }}, {{ deal.partner.name }} </span>
                  </el-col>
                  <el-col v-if="deal.payer" :span="8">
                    <span class="font-bold"> {{ $t('message.payer') }}: </span> 
                    <span> {{ deal.payer.custom_id }}, {{ deal.payer.name }} </span>
                  </el-col>

                  <!-- Addresses  -->
                  <el-col v-if="deal.client_deal_address && deal.deal_type_id != 1" :span="8">
                    <span class="font-bold"> {{ $t('message.sender_address') }}: </span> 
                    <span> {{ (deal.deal_type_id === 1) ? '' : deal.client_deal_address }} </span>
                  </el-col>

                  <el-col v-if="(deal.deal_type_id === 1 && deal.client_deal_address) || deal.to_client_deal_address" :span="8">
                    <span class="font-bold"> {{ $t('message.reciever_address') }}: </span> 
                    <span> {{ (deal.deal_type_id === 1) ? deal.client_deal_address : deal.to_client_deal_address }} </span>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mm_font_14">
                  <!-- Directions  -->
                  <el-col v-if="deal.from_filial" :span="8">
                    <span class="font-bold"> {{ $t('message.from_filial') }}: </span> 
                    <span> {{ deal.from_filial.name }} </span>
                  </el-col>
                  <el-col v-if="deal.to_filial" :span="8">
                    <span class="font-bold"> {{ $t('message.to_filial') }}: </span> 
                    <span> {{ deal.to_filial.name }} </span>
                  </el-col>
                  <el-col v-if="deal.directionTariff" :span="8">
                    <span class="font-bold"> {{ $t('message.directionTariff') }}: </span> 
                    <span> {{ deal.directionTariff.name }} </span>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mm_font_14">
                  <!-- Calculations  -->
                  <el-col v-if="deal.all_weight" :span="8">
                    <span class="font-bold"> {{ $t('message.all_weight') }}: </span> 
                    <span> {{ deal.all_weight }} {{ $t('message.kg') }}</span>
                  </el-col>

                  <el-col v-if="deal.product_total" :span="8">
                    <span class="font-bold"> {{ $t('message.delivery_total') }}: </span> 
                    <span> {{ deal.product_total | formatMoney }} </span>
                  </el-col>

                  <el-col v-if="deal.total_services_price" :span="8">
                    <span class="font-bold"> {{ $t('message.service_total') }}: </span> 
                    <span> {{ deal.total_services_price | formatMoney }} </span>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- PRODUCTS -->
            <div v-loading="loadingProducts">
              <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 mm_font_14">
                  <el-table
                    class="rounded-sm"
                    :data="deal.dealProducts"
                    border
                    style="width: 100%">

                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">
                        {{ scope.row.product ? scope.row.product.name : '' }}
                        <span v-if="scope.row.comment">( {{ scope.row.comment }} )</span>
                        
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.track_code')">
                        <template slot-scope="scope">
                            {{ scope.row.tracking_code }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.weight_in_kg')" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.weight }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.quantity_y')" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.quantity }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.from_filial')">
                        <template slot-scope="scope">
                            {{ scope.row.from_filial_remainder }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.packing')">
                        <template slot-scope="scope">
                            {{ scope.row.packing }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.on_flight')">
                        <template slot-scope="scope">
                            {{ scope.row.on_flight }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.unpacking')">
                        <template slot-scope="scope">
                            {{ scope.row.unpacking }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.to_filial')">
                        <template slot-scope="scope">
                            {{ scope.row.to_filial_remainder }}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.client')" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.client_remainder }}
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <!-- SERIVCES -->
            <div v-loading="loadingProducts">
              <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 mm_font_14">
                  <el-row :gutter="20">
                      <p class="font-bold">{{ $t('message.services') }}</p>
                      <span v-if="deal.serviceList && deal.serviceList.length == 0" class="font-bold">
                        {{ $t('message.there_is_no_services') }}
                      </span>
                      <el-col v-for="(service, index) in deal.serviceList" :key="'key_' + index" :span="12" class="mb-4">
                          <span class="font-bold">{{ service.name }}: </span>  
                          <span :class="processServiceClass(service.process)">
                            {{ service.process ? service.process.name : ''}}
                          </span>
                      </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <!-- HISTORY -->
            <div v-loading="loadingProducts">
              <dealHistory :historyList="deal.historyList"></dealHistory>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-drawer
        :visible.sync="drawerDealCreate"
        :with-header="false"
        size="95%"
        class="body_scroll"
        ref="drawerDealCreate"
        @opened="drawerOpened('drawerDealCreateChild')"
        @closed="drawerClosed('drawerDealCreateChild')"
      >
        <AppModal
            ref="drawerDealCreateChild"
            :selectedDeal="selectedDeal"
            :dealCreate="false"
            :cargo_type="'commercial'"
            drawer="drawerDealCreate"
        />
      </el-drawer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import selectDeal from "@/components/inventory/select-deal";
import dealHistory from "@/views/deals/components/dealHistory/dealHistory";
import AppModal from "@/views/deals/components/add-modal";

export default {
    name: "trakingDeal",
    components: {
      selectFromFilial,
      selectToFilial,
      selectClient,
      selectDeal,
      dealHistory,
      AppModal
    },
    data() {
        return {
          loadingData: false,
          loadingProducts: false,
          drawerDealCreate: false,
          activeNames: [],
          filterForm: {
				    search: '',
          },
          searchedDeals: [],
          selectedDeal: '',
        };
    },
    watch: {
      filterForm: {
          handler: async function(newVal, oldVal) {
            if(newVal.search && newVal.search.length > 3){
              this.updateList(newVal);
            }
          },
          deep: true,
          immediate: true
      },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            dealProducts: "dealProducts/dealProducts",
            client: "clients/model",
            deal: "deals/model",
            dealList: "deals/list",
            historyList: "dealHistory/list",
            dealServices: "dealService/dealServices",
            types: "dealHistory/types",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    mounted() {
      if(this.currencies && this.currencies.length === 0){
        this.updateCurrencyInventory();
      }
      if (this.types && this.types.length === 0) this.updateTypes();
    },
    methods: {
        ...mapActions({
            updateDealList: "deals/index",
            showDealProducts: "dealProducts/show",
            showDeal: "deals/show",
            showClient: "clients/show",
            updateDealHistoryList: "dealHistory/index",
            showDealServices: "dealService/showDealServices",
            updateTypes: "dealHistory/getHistoryTypes",
        }),     

        updateList: _.debounce(async function(query) {
          if(query.search){
            this.loadingData = true;
            this.updateDealList(query)
              .then(res => {
                this.searchedDeals = JSON.parse(JSON.stringify(this.dealList));
                this.loadingData = false;
              })
              .catch(err => {
                this.loadingData = false;
              });
          }
        }, 1000),

        handleChange(val) {
          if(val && val.length > 0 && this.searchedDeals && this.searchedDeals.length > 0){
            const opened_id = val[val.length - 1];
            const indexOfDeal = this.searchedDeals.findIndex(deal => deal.id === opened_id);
            if(indexOfDeal >= 0 && opened_id){
              this.showOpenedDeal(opened_id, indexOfDeal);
              this.fetchHistoryData(opened_id, indexOfDeal);
              this.fetchDealServices(opened_id, indexOfDeal);
            }
          }
        },

        fetchDealServices(opened_id, deal_index) {
          if(!this.searchedDeals[deal_index].serviceList){
            this.loadingProducts = true;
            this.showDealServices({deal_id: opened_id})
              .then(res => {
                this.$set(this.searchedDeals[deal_index], 'serviceList', JSON.parse(JSON.stringify(this.dealServices)));
                this.loadingProducts = false;
              })
              .catch(err => {
                this.loadingProducts = false;
              });
          }
        },
        
        fetchHistoryData(opened_id, deal_index){
          if(!this.searchedDeals[deal_index].historyList){
            this.loadingProducts = true;
            this.updateDealHistoryList({deal_id: opened_id})
                .then(res => {
                    this.$set(this.searchedDeals[deal_index], 'historyList', JSON.parse(JSON.stringify(this.historyList)));
                    this.loadingProducts = false;
                })
                .catch(err => {
                    this.loadingProducts = false;
                });
          }
        },

        async showOpenedDeal(deal_id, deal_index){
          if(!this.searchedDeals[deal_index].dealProducts){
            this.loadingProducts = true;
            await this.showDealProducts({id: deal_id})
                .then((res) => {
                  this.$set(this.searchedDeals[deal_index], 'dealProducts', JSON.parse(JSON.stringify(this.dealProducts)));
                  this.loadingProducts = false;
                })
                .catch((err) => {
                  this.loadingProducts = false;
                });
          }
        },

        editDeal(model){
            this.selectedDeal = model;
            this.drawerDealCreate = true;
        },

        processServiceClass(process) { 
          if(process && process.id){
            if(process.id == 1){
              return 'mm_active';
            }else if(process.id == 2){
              return 'mm_process';
            }else if(process.id == 3){
              return 'mm_success';
            }
          }
          return '';
        },

        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('sources/EMPTY_LIST');
        next()
    },
};
</script>
<style>
.mm_red {
  color: rgb(189, 38, 38);
}
.mm_green {
  color: rgb(38, 189, 88);
}
.mm_blue {
  color: rgb(52, 72, 255);
}
.mm_font_14 {
  font-size: 14px;
}
.mm_font_16 {
  font-size: 16px;
}
.mm_space_between {
  display: flex;
  justify-content: space-between;
}
.mm_active{
    color: #E6A23C;
}
.mm_process{
    color: #409EFF;
}
.mm_success{
    color: #67C23A;
}
.link{
    text-decoration: none;
}
.mm_background .el-collapse-item__content {
  background-color: #eef2f4;
}
.mm_min_width {
  min-height: 500px;
}
</style>
